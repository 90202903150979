import * as ReduxObservable from 'redux-observable'
import * as Router from 'router'
import {ajax} from 'rxjs/ajax'
import {catchError, filter, map, switchMap} from 'rxjs/operators'
import { AjaxWrapper } from '../generic/ajaxWrapper'
import { ALL_METADATA } from '../generic/epic'
import * as TwoFactorAuthConstants from '../pages/2fa-auth/constants'
import * as ErrorConstants from '../pages/error/constants'
import * as ForgotPasswordConstants from '../pages/forgot-password/constants'
import * as HandledErrorConstants from '../pages/handled-error/constants'
import * as IntegrationsCallbackConstants from '../pages/integrations-callback/constants'
import * as LoginConstants from '../pages/login/constants'
import * as LogoutConstants from '../pages/logout/constants'
import * as ResetPasswordConstants from '../pages/reset-password/constants'
import * as SignupConstants from '../pages/signup/constants'
import * as StoreConstants from '../store/constants'
import * as Constants from './constants'

const validateToken = (action$, state$, {history}) =>
  action$
    .ofType(ALL_METADATA)
    .pipe(
      filter(() => {
        if (history.location.pathname === LoginConstants.LOGIN_BASE_ROUTE || history.location.pathname === ForgotPasswordConstants.FORGOT_PASSWORD_BASE_ROUTE) {
          window.localStorage.removeItem(Constants.RASA_AUTH_TOKEN)
          return true
        }
        return true
      }),
      filter(() => history.location.pathname !== SignupConstants.SIGNUP_BASE_ROUTE
      && history.location.pathname !== ErrorConstants.GENERIC_ERROR_ROUTE
      && history.location.pathname !== ForgotPasswordConstants.FORGOT_PASSWORD_BASE_ROUTE
      && history.location.pathname !== ForgotPasswordConstants.FORGOT_PASSWORD_SUCCESS_ROUTE
      && history.location.pathname !== HandledErrorConstants.HANDLED_ERROR_BASE_ROUTE
      && history.location.pathname !== IntegrationsCallbackConstants.INTEGRATIONS_CALLBACK_BASE_ROUTE
      && history.location.pathname !== ResetPasswordConstants.RESET_PASSWORD_BASE_ROUTE
      && history.location.pathname !== LoginConstants.LOGIN_BASE_ROUTE
      && history.location.pathname !== TwoFactorAuthConstants.FA_BASE_ROUTE),
      map(() => window.localStorage.getItem(Constants.RASA_AUTH_TOKEN)),
      switchMap((authToken: string) =>
        ajax({
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken,
          },
          method: 'get',
          url: state$.value.config.api + '/auth/token',
        }).pipe(
          map((payload) => {
            AjaxWrapper.setToken(authToken)
            return {type: Constants.VALID_TOKEN}
          }),
          catchError((error) => {
            // eslint-disable-next-line no-console
            console.error('token error', error)
            // New Onboarding Email Verification code handling
            const queryParams: URLSearchParams = new URLSearchParams(history.location.search)
            if (queryParams.get('c')) {
              window.localStorage.setItem(StoreConstants.EMAIL_VERIFICATION_CODE, queryParams.get('c'))
            }
            window.localStorage.removeItem(StoreConstants.SELECTED_COMMUNITY)
            window.localStorage.setItem(LoginConstants.REDIRECT_TO,
              ( history.location.pathname === LoginConstants.LOGIN_BASE_ROUTE ||
                history.location.pathname === ForgotPasswordConstants.FORGOT_PASSWORD_BASE_ROUTE ||
                history.location.pathname === LogoutConstants.LOGOUT_BASE_ROUTE ) ? '/'
              : history.location.pathname + history.location.search)
            return [
              {type: Constants.INVALID_TOKEN},
              Router.push(LoginConstants.LOGIN_BASE_ROUTE),
            ]
          }))))

const bypassValidation = (action$, state$, {history}) =>
  action$
  .ofType(ALL_METADATA)
  .pipe(
    filter(() => history.location.pathname === SignupConstants.SIGNUP_BASE_ROUTE
    || history.location.pathname === ForgotPasswordConstants.FORGOT_PASSWORD_BASE_ROUTE
    || history.location.pathname === ForgotPasswordConstants.FORGOT_PASSWORD_SUCCESS_ROUTE
    || history.location.pathname === HandledErrorConstants.HANDLED_ERROR_BASE_ROUTE
    || history.location.pathname === IntegrationsCallbackConstants.INTEGRATIONS_CALLBACK_BASE_ROUTE
      || history.location.pathname !== LoginConstants.LOGIN_BASE_ROUTE
      || history.location.pathname === ResetPasswordConstants.RESET_PASSWORD_BASE_ROUTE),
    map(() => ({type: 'UNAUTHENTICATED'})),
  )

export const epic = ReduxObservable.combineEpics(bypassValidation, validateToken)
